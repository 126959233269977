import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectRegions } from 'store/selectors/facets'
import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import AutocompleteField from './AutocompleteField'
import FilterHeader from './FilterHeader'

const RegionHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const regions = useSelector(selectRegions)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['location'] || !!filters['locationName']

  const getCustomResults = searchText => {
    if (regions.length === 0) {
      return []
    }
    const name = t.explorer.provinceCodes[searchText.toLowerCase()]
    if (!name) {
      return []
    }
    const province = regions[0].children.find(province => province.name === name)
    if (!province) {
      return []
    }
    return [{ type: 'location', name, id: province.id }]
  }

  return (
    <FilterHeader column={column} isActive={isActive}>
      <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
        {t.explorer.search_locations}
      </p>
      <AutocompleteField
        field="location"
        defaultValue={filters['locationName']}
        defaultSelectedValue={filters['location']}
        getCustomResults={getCustomResults}
        inputProps={{ placeholder: t.explorer.locationNamePlaceholder }}
      />
    </FilterHeader>
  )
}

export default RegionHeader
