import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import AutocompleteField from './AutocompleteField'
import FilterHeader from './FilterHeader'
import SimpleNumberField from './SimpleNumberField'

const CharityHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive =
    !!filters['charity'] ||
    !!filters['charityName'] ||
    !!filters['recipientSizeMin'] ||
    !!filters['recipientSizeMax']

  return (
    <FilterHeader column={column} isActive={isActive}>
      <div>
        <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
          {t.explorer.search_by_name}
        </p>
        <AutocompleteField
          field="charity"
          defaultValue={filters['charityName']}
          defaultSelectedValue={filters['charity']}
          inputProps={{ placeholder: t.explorer.recipientNamePlaceholder }}
        />
      </div>
      <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
        {t.explorer.filter_by_recipient_size}
      </p>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <div>
          <SimpleNumberField
            id="recipientSizeMin"
            label={t.explorer.min_revenue}
            defaultValue={filters['recipientSizeMin']}
            min={0}
            max={100_000_000_000}
            step={1000}
          />
        </div>
        <p className="tw-mt-6">{t.explorer.rangeTo}</p>
        <div>
          <SimpleNumberField
            id="recipientSizeMax"
            label={t.explorer.max_revenue}
            defaultValue={filters['recipientSizeMax']}
            min={0}
            max={100_000_000_000}
            step={1000}
          />
        </div>
      </div>
    </FilterHeader>
  )
}

export default CharityHeader
