import React from 'react'

const makePart = (text, index) => (
  <React.Fragment key={index}>
    {index % 2 ? <span className="ge-highlight">{text}</span> : text}
  </React.Fragment>
)

export const highlightText = (text, tag = /<\/?em>/g) => text && text.split(tag).map(makePart)

/** Makes sure the total length of the given text content does not exceed the
 *  given length.
 *
 *  @warning Expects `text` to be a value returned by `highlightText`.
 */
export const truncateHighlightedText = (text, length) => {
  return text.reduce(
    (out, part, index) => {
      const content = index % 2 ? part.props.children.props.children : part.props.children
      if (out.length >= length) {
        return out
      } else if (out.length + content.length > length) {
        const newText = content.substring(0, length - out.length)
        const newPart = makePart(newText, index)
        return { parts: [...out.parts, newPart], length }
      } else {
        return { parts: [...out.parts, part], length: out.length + content.length }
      }
    },
    { parts: [], length: 0 }
  ).parts
}
