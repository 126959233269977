import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dialog, IconButton } from '@mui/material'
import { Check, Close, UnfoldMore } from '@mui/icons-material'

import { trackGiftSearchButtonClick } from 'utils/mixpanel'

import { getFunderById } from 'store/actions/profile'
import { getNotesByFunderId } from 'store/actions/notes'
import { selectPipeline } from 'store/selectors/pipeline'

import ProfileCard from 'components/ProfileCard'

import { highlightText } from '../helpers'

const FunderCell = ({ cell }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const { results: pipelineData } = useSelector(selectPipeline)

  const [showModal, setShowModal] = useState(false)

  const funder = cell.getValue()

  const handleClose = () => {
    setShowModal(false)
  }

  const handleOpen = async e => {
    dispatch(getFunderById(funder.id))
    dispatch(getNotesByFunderId(funder.id))
    setShowModal(true)
    await trackGiftSearchButtonClick({ button: 'table_popup.funder' })
  }

  const organizationFunderOpportunity = pipelineData.find(
    opportunity => opportunity.funder.id || opportunity.funder === funder.id
  )
  const inPipeline = !!organizationFunderOpportunity?.pipelineStage

  const name = cell.row.original.highlight?.['funder.name']?.[0] || funder.name

  return (
    <div>
      <div className="ge-table-cell">
        <span className="tw-mr-1">{highlightText(name)}</span>
        <IconButton className="ge-icon-button" onClick={handleOpen}>
          <div className="">
            <UnfoldMore style={{ transform: 'rotate(45deg)', marginTop: '4px' }} />
          </div>
        </IconButton>
        {inPipeline && (
          <IconButton
            className="ge-icon-button ge-pipeline-button"
            title={t.explorer.in_your_pipeline}
          >
            <Check />
          </IconButton>
        )}
      </div>
      <Dialog open={showModal} onClose={handleClose} className="Explorer ge-profile-card">
        <div className="ge-dialog-close">
          <Close onClick={handleClose} />
        </div>
        <ProfileCard isDialog />
      </Dialog>
    </div>
  )
}

export default FunderCell
