import { Autocomplete, Chip, TextField } from '@mui/material'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useDebounce } from 'hooks'
import backend from 'utils/backend'

import { selectGiftSearchNameCache } from 'store/selectors/giftSearch'
import { setGiftSearchNameCacheEntry } from 'store/actions/giftSearch'

const AutocompleteField = ({
  field,
  defaultValue,
  defaultSelectedValue,
  getCustomResults,
  inputProps = {},
  ...props
}) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState(defaultValue || '')
  const debouncedSearchText = useDebounce(searchText, 500)
  const [autocompleteResults, setAutocompleteResults] = useState([])
  const [autocompleteValue, setAutocompleteValue] = useState([])

  const [nameLoading, setNameLoading] = useState(true)
  const nameCache = useSelector(selectGiftSearchNameCache)

  useEffect(async () => {
    if (!nameLoading) {
      return
    }

    if (defaultSelectedValue) {
      const value = _.get(nameCache, `${field}.${defaultSelectedValue}`, '')
      if (value) {
        setAutocompleteValue([{ type: field, id: defaultSelectedValue, name: value }])
        setNameLoading(false)
      }
    } else {
      setAutocompleteValue([])
      setNameLoading(false)
    }
  }, [defaultSelectedValue])

  useEffect(async () => {
    if (debouncedSearchText.length >= 3) {
      const fieldParams = field ? `&field=${field}` : ''
      const url = `api/search/giftsautocomplete/?search=${debouncedSearchText}${fieldParams}`

      try {
        const {
          data: { results }
        } = await backend.get(url)
        setAutocompleteResults(results)
      } catch (error) {
        setAutocompleteResults([])
      }
    }
    if (getCustomResults) {
      const customResults = getCustomResults(debouncedSearchText)
      setAutocompleteResults(prev => [...customResults, ...prev])
    }
  }, [debouncedSearchText])

  const handleAutocompleteSelect = (e, value) => {
    setAutocompleteResults([])

    if (typeof value[0] === 'string') {
      e.target.form.requestSubmit()
    } else {
      setAutocompleteValue(value)
      setSearchText('')

      if (value.length > 0) {
        dispatch(setGiftSearchNameCacheEntry(field, value[0].id, value[0].name))
      }
    }
  }

  const handleSearchChange = (e, value, reason) => {
    if (reason === 'reset') {
      return
    }

    // The value param appears to be broken when pressing the enter key,
    // so use the event target's value instead.
    const query = e.target.value || ''
    setSearchText(query)

    setAutocompleteValue([])

    if (query.length < 3) {
      setAutocompleteResults([])
    }

    setNameLoading(false)
  }

  const value = autocompleteValue.length > 0 ? autocompleteValue[0].id : ''

  return (
    <div className="relative">
      <Autocomplete
        fullWidth
        freeSolo
        multiple
        blurOnSelect
        size="small"
        filterOptions={x => x}
        options={autocompleteResults}
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        renderOption={(props, option) => {
          const { key, id, ...optionProps } = props
          return (
            <li
              key={id}
              {...optionProps}
              className="tw-flex tw-gap-1 tw-p-2 tw-pl-3 hover:tw-bg-lighterGrey"
            >
              <span className="tw-text-darkGrey">{t.explorer.autocompleteTypes[option.type]}:</span>
              <span>{option.name}</span>
            </li>
          )
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={option.id} label={option.name} {...getTagProps(index)} />
          ))
        }
        onChange={handleAutocompleteSelect}
        value={autocompleteValue}
        onInputChange={handleSearchChange}
        inputValue={searchText}
        renderInput={params => <TextField {...params} {...inputProps} name={`${field}Name`} />}
        componentsProps={{ popper: { className: 'Explorer', placement: 'bottom-start' } }}
        {...props}
      />
      <input name={field} type="number" hidden readOnly value={value} />
    </div>
  )
}

export default AutocompleteField
