import {
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ChevronLeft,
  ChevronRight,
  FilterListOff,
  FirstPage,
  LastPage
} from '@mui/icons-material'
import { Box, IconButton, ListItemIcon, MenuItem, TableSortLabel, Tooltip } from '@mui/material'

import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { MaterialReactTable } from 'material-react-table'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'

import { trackGiftSearchButtonClick } from 'utils/mixpanel'

import {
  setGiftSearchFilterByKey,
  setGiftSearchPagination,
  setGiftSearchSorting
} from 'store/actions/giftSearch'

import { highlightText } from '../helpers'

import FullTextModal from './FullTextModal'
import FunderHeader from './FunderHeader'
import FunderCell from './FunderCell'
import CharityCell from './CharityCell'
import CharityHeader from './CharityHeader'
import AmountHeader from './AmountHeader'
import FocusHeader from './FocusHeader'
import LocationHeader from './LocationHeader'
import YearHeader from './YearHeader'
import PurposeHeader from './PurposeHeader'
import SimpleSelectField from './SimpleSelectField'

// Copied from Material React Table src/menus/MRT_ColumnActionMenu.tsx
export const commonMenuItemStyles = {
  py: '6px',
  my: 0,
  justifyContent: 'space-between',
  alignItems: 'center'
}

export const commonListItemStyles = {
  display: 'flex',
  alignItems: 'center'
}

const MAX_RESULT_COUNT = 10000

const DataTable = ({ records, count }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const { loading, pagination, sorting, filters } = useSelector(({ giftSearch }) => giftSearch)

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'charity',
        filters: ['charity', 'charityName', 'recipientSizeMin', 'recipientSizeMax'],
        header: t.explorer.recipient,
        size: 110,
        Header: DataTableHeader(CharityHeader),
        Cell: ({ cell }) => <CharityCell cell={cell} />
      },
      {
        accessorKey: 'funder',
        filters: ['funder', 'funderName'],
        header: t.explorer.funder,
        size: 110,
        Header: DataTableHeader(FunderHeader),
        Cell: ({ cell }) => <FunderCell cell={cell} />
      },
      {
        accessorKey: 'gift_amount',
        filters: ['giftAmountMin', 'giftAmountMax'],
        header: t.explorer.amount,
        size: 110,
        Header: DataTableHeader(AmountHeader),
        Cell: ({ cell }) => {
          const numberValue = cell.row.original.giftAmount
          if (!numberValue) {
            return null
          }

          const formattedValue = new Intl.NumberFormat(`${i18n.language}-CA`, {
            style: 'currency',
            currency: 'CAD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          }).format(numberValue)

          return <div>{formattedValue}</div>
        }
      },
      {
        accessorKey: 'focus',
        filters: ['causes', 'internationals', 'populations'],
        header: t.explorer.focus,
        size: 110,
        Header: DataTableHeader(FocusHeader),
        Cell: ({ cell }) => {
          const name =
            cell.row.original.highlight?.['focus.name']?.[0] ||
            cell.row.original.focus?.name ||
            t.explorer.focusUnknown
          return <div>{highlightText(name)}</div>
        }
      },
      {
        accessorKey: 'location',
        filters: ['location', 'locationName'],
        header: t.explorer.location,
        size: 110,
        Header: DataTableHeader(LocationHeader),
        Cell: ({ cell }) => {
          const name =
            cell.row.original.highlight?.['location.name']?.[0] || cell.row.original.location?.name
          return <div>{highlightText(name)}</div>
        }
      },
      {
        accessorKey: 'year',
        filters: ['yearMin', 'yearMax'],
        header: t.explorer.year,
        size: 100,
        Header: DataTableHeader(YearHeader),
        Cell: ({ cell }) => {
          return <div>{cell.row.original.year || null}</div>
        }
      },
      {
        accessorKey: 'details',
        filters: ['details'],
        header: t.explorer.description,
        size: 110,
        enableSorting: false,
        Header: DataTableHeader(PurposeHeader),
        Cell: ({ cell }) => {
          const rawText = cell.row.original.details
          const fullText = cell.row.original.highlight?.details?.[0] || rawText
          const maxLength = 50

          if (!fullText) {
            return <div>{`-`}</div>
          }
          if (rawText.length > maxLength) {
            return <FullTextModal title={t.explorer.detailsModalTitle} fullText={fullText} />
          }

          return <div>{highlightText(fullText)}</div>
        }
      }
    ],
    []
  )

  const customLocalizationEn = {
    ...MRT_Localization_EN,
    showAllColumns: 'Unhide all columns',
    move: 'Move column'
  }

  const customLocalizationFr = {
    ...MRT_Localization_FR
  }

  const tableLocalization = i18n.language === 'fr' ? customLocalizationFr : customLocalizationEn

  const renderActionMenuItems = ({ closeMenu, column, internalColumnMenuItems }) => {
    const isActive = column.columnDef.filters.some(filter => !!filters[filter])

    const handleClearFilter = () => {
      dispatch(
        setGiftSearchFilterByKey(
          Object.fromEntries(column.columnDef.filters.map(filter => [filter, null]))
        )
      )
      closeMenu()
    }

    return [
      <MenuItem
        disabled={!isActive}
        divider={column.columnDef.enableSorting === false}
        key={3}
        onClick={handleClearFilter}
        sx={commonMenuItemStyles}
      >
        <Box sx={commonListItemStyles}>
          <ListItemIcon>
            <FilterListOff />
          </ListItemIcon>
          {tableLocalization.clearFilter}
        </Box>
      </MenuItem>,
      ...internalColumnMenuItems
    ]
  }

  const ref = useRef(null)

  const scrollToStart = () => {
    const tableRef = ref.current?.refs.tableContainerRef?.current
    if (tableRef) {
      tableRef.scrollTo({ behavior: 'smooth', left: 0 })
    }
  }

  const scrollToEnd = () => {
    const tableRef = ref.current?.refs.tableContainerRef?.current
    if (tableRef) {
      const offset = tableRef.firstChild.offsetWidth - tableRef.offsetWidth
      tableRef.scrollTo({ behavior: 'smooth', left: offset })
    }
  }

  return (
    <>
      <div className="ge-scroll">
        <div onClick={scrollToStart}>
          <ArrowBack style={{ height: '18px', width: '18px' }} />
        </div>
        <div onClick={scrollToEnd}>
          <span>{t.explorer.scroll}</span>
          <ArrowForward style={{ height: '18px', width: '18px' }} />
        </div>
      </div>
      <MaterialReactTable
        tableInstanceRef={ref}
        columns={columns}
        data={records}
        enableTopToolbar={false}
        enableColumnFilters={false}
        muiTablePaginationProps={{
          component: DataTablePagination
        }}
        muiTableProps={{
          sx: {
            borderRight: '1px solid #D9E3E9',
            borderTop: '1px solid #D9E3E9'
          }
        }}
        muiTableHeadCellProps={{
          sx: {
            borderLeft: '1px solid #D9E3E9',
            borderBottom: '1px solid #D9E3E9',
            paddingRight: '0.4rem'
          }
        }}
        muiTableBodyCellProps={{
          sx: {
            borderLeft: '1px solid #D9E3E9',
            borderBottom: '1px solid #D9E3E9',
            paddingRight: '0.4rem'
          }
        }}
        localization={tableLocalization}
        icons={{
          SortIcon: props => <ArrowDownward {...props} />
        }}
        renderColumnActionsMenuItems={renderActionMenuItems}
        manualPagination
        manualSorting
        onPaginationChange={update => dispatch(setGiftSearchPagination(update(pagination)))}
        onSortingChange={update => dispatch(setGiftSearchSorting(update(sorting)))}
        rowCount={Math.min(count, MAX_RESULT_COUNT)}
        state={{
          isLoading: loading,
          pagination,
          sorting
        }}
      />
    </>
  )
}

// Modified version of MRT_TableHeadCellSortLabel
const DataTableHeader =
  component =>
  ({ header, table, ...props }) => {
    const {
      options: {
        icons: { ArrowDownwardIcon },
        localization
      }
    } = table
    const { column } = header
    const { columnDef } = column
    const sorted = column.getIsSorted()

    const sortTooltip = sorted
      ? sorted === 'desc'
        ? localization.sortedByColumnDesc.replace('{column}', columnDef.header)
        : localization.sortedByColumnAsc.replace('{column}', columnDef.header)
      : column.getNextSortingOrder() === 'desc'
      ? localization.sortByColumnDesc.replace('{column}', columnDef.header)
      : localization.sortByColumnAsc.replace('{column}', columnDef.header)

    const headerElement = component({ header, table, ...props })

    const handleClick = async e => {
      e.stopPropagation()
      column.getToggleSortingHandler()?.(e)
      await trackGiftSearchButtonClick({ button: `sort_by.${columnDef.accessorKey}` })
    }

    return (
      <div className="tw-flex">
        {headerElement}
        {columnDef.enableSorting !== false && (
          <Tooltip arrow placement="top" title={sortTooltip}>
            <TableSortLabel
              className="ge-table-sort"
              aria-label={sortTooltip}
              active={!!sorted}
              direction={sorted ? sorted : undefined}
              sx={{
                flex: '0 0',
                width: '2.4ch',
                transform: 'translateX(-0.5ch)'
              }}
              IconComponent={ArrowDownwardIcon}
              onClick={handleClick}
            />
          </Tooltip>
        )}
      </div>
    )
  }

const DataTablePagination = ({ ...props }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const {
    pagination,
    results: { count }
  } = useSelector(({ giftSearch }) => giftSearch)

  const pageSizeOptions = [5, 10, 25, 50, 100].map(count => ({ value: count, label: `${count}` }))

  const boundedCount = Math.min(count, MAX_RESULT_COUNT)
  const lastPage = Math.max(Math.floor((boundedCount - 1) / pagination.pageSize), 0)

  const isFirstPage = pagination.pageIndex === 0
  const isLastPage = pagination.pageIndex === lastPage

  const handlePageSizeChange = async e => {
    dispatch(setGiftSearchPagination({ ...pagination, pageSize: e.target.value }))
    await trackGiftSearchButtonClick({ button: 'pagination.page_size' })
  }

  const handleGoToFirstPage = async () => {
    dispatch(setGiftSearchPagination({ ...pagination, pageIndex: 0 }))
    await trackGiftSearchButtonClick({ button: 'pagination.first_page' })
  }

  const handleGoToPreviousPage = async () => {
    dispatch(setGiftSearchPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 }))
    await trackGiftSearchButtonClick({ button: 'pagination.previous_page' })
  }

  const handleGoToNextPage = async () => {
    dispatch(setGiftSearchPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 }))
    await trackGiftSearchButtonClick({ button: 'pagination.next_page' })
  }

  const handleGoToLastPage = async () => {
    dispatch(setGiftSearchPagination({ ...pagination, pageIndex: lastPage }))
    await trackGiftSearchButtonClick({ button: 'pagination.last_page' })
  }

  const numberFormatter = new Intl.NumberFormat(`${i18n.language}-CA`)

  const pageRangeStart = boundedCount > 0 ? pagination.pageIndex * pagination.pageSize + 1 : 0
  const pageRangeEnd = Math.min((pagination.pageIndex + 1) * pagination.pageSize, boundedCount)
  const pageRangeFormatKey = boundedCount === MAX_RESULT_COUNT ? 'pageRangeOverLimit' : 'pageRange'
  const pageRangeText = t.explorer.pagination[pageRangeFormatKey]
    .replace('{start}', numberFormatter.format(pageRangeStart))
    .replace('{end}', numberFormatter.format(pageRangeEnd))
    .replace('{count}', numberFormatter.format(boundedCount))

  return (
    <div className={`${props.className} ge-pagination`}>
      <div className="ge-pagination-size">
        <span>{t.explorer.pagination.rowsPerPage}</span>
        <SimpleSelectField
          options={pageSizeOptions}
          value={pagination.pageSize}
          onChange={handlePageSizeChange}
        />
      </div>
      <div className="ge-pagination-page">
        <span>{pageRangeText}</span>
      </div>
      <div className="ge-pagination-actions">
        <IconButton onClick={handleGoToFirstPage} disabled={isFirstPage}>
          <FirstPage />
        </IconButton>
        <IconButton onClick={handleGoToPreviousPage} disabled={isFirstPage}>
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={handleGoToNextPage} disabled={isLastPage}>
          <ChevronRight />
        </IconButton>
        <IconButton onClick={handleGoToLastPage} disabled={isLastPage}>
          <LastPage />
        </IconButton>
      </div>
    </div>
  )
}

export default DataTable
