import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectflatSearchFacets } from 'store/selectors/facets'
import { selectGiftSearchNameCache } from 'store/selectors/giftSearch'

const ResultSummary = ({ filters, handleRemoveFilter, displayMode = false }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  const facets = useSelector(selectflatSearchFacets)
  const nameCache = useSelector(selectGiftSearchNameCache)

  const filterKeys = Object.keys(filters)
  const activeFilterKeys = filterKeys.filter(k => !!filters[k])

  if (activeFilterKeys.length === 0) {
    return null
  }

  const generateButtonValue = (filterKey, filterValue) => {
    if (['keyword', 'funderName', 'charityName', 'locationName', 'details'].includes(filterKey)) {
      return `"${filterValue}"`
    } else if (
      ['giftAmountMin', 'giftAmountMax', 'recipientSizeMin', 'recipientSizeMax'].includes(filterKey)
    ) {
      return new Intl.NumberFormat(`${i18n.language}-CA`, {
        style: 'currency',
        currency: 'CAD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }).format(filterValue)
    } else if (['causes', 'populations', 'internationals'].includes(filterKey)) {
      const selected = facets.filter(
        facet => facet.facetType === filterKey && filterValue.includes(facet.id)
      )
      return selected.map(facet => facet.title).join(', ')
    } else if (['funder', 'charity', 'location'].includes(filterKey)) {
      return _.get(nameCache, `${filterKey}.${filterValue}`, '')
    } else {
      return filterValue
    }
  }

  const generateButtonText = (filterKey, filterValue) => {
    return `${t.explorer.filters[filterKey]}: ${generateButtonValue(filterKey, filterValue)}`
  }

  return (
    <div className="tw-flex tw-flex-col tw-md:flex-row tw-items-start tw-gap-2">
      <div className="tw-flex-none tw-mt-1 tw-font-semibold">
        {displayMode ? t.explorer.saved_search_summary_text : t.explorer.results_summary_text}
      </div>
      <div className="tw-inline-flex tw-items-center tw-gap-2 tw-mb-2 tw-flex-wrap">
        {activeFilterKeys.map(filterKey => {
          const filterValue = filters[filterKey]
          if (!filterValue) {
            return null
          }
          return (
            <div
              key={`${filterKey}-${filterValue}`}
              className="ge-result-button tw-hover:bg-lightGrey tw-border tw-border-grey tw-border-solid tw-bg-white tw-rounded tw-py-1 tw-px-2 tw-flex tw-items-center tw-gap-1"
            >
              {generateButtonText(filterKey, filterValue)}
              {!displayMode && (
                <IconButton
                  className="ge-icon-button"
                  onClick={() => handleRemoveFilter(filterKey)}
                >
                  <Close className="" />
                </IconButton>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ResultSummary
