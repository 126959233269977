import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paper } from '@mui/material'
import { Undo, Close } from '@mui/icons-material'

import { trackGiftSearchButtonClick } from 'utils/mixpanel'

import {
  debouncedGetGiftStats,
  debouncedSearchGifts,
  resetGiftSearch,
  setGiftSearchFilterByKey,
  setGiftSearchFilters,
  syncGiftSearchURL,
  updateGiftSearchNameCache
} from 'store/actions/giftSearch'
import { getPipelines } from 'store/actions/pipeline'
import { getPipelineStages } from 'store/actions/pipelineStages'
import { getSavedSearches } from 'store/actions/savedSearches'
import { selectPipelineStages } from 'store/selectors/pipelineStages'
import { selectSavedGiftSearches } from 'store/selectors/savedSearch'

import SearchBar from './components/SearchBar'
import SavedSearchesDropdown from './components/SavedSearchesDropdown'
import SaveSearch from './components/SaveSearch'
import DataTable from './components/DataTable'
import ButtonWithIcon from './components/ButtonWithIcon'
import ResultsSummary from './components/ResultsSummary'
import BarChart from './components/BarChart'
import PieChart from './components/PieChart'
import ReportProfileError from 'pages/profile/components/ReportProfileError'

const Explorer = () => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()
  const location = useLocation()

  const [filterStack, setFilterStack] = useState([])

  const { urlSynced, pagination, sorting, filters, results, stats } = useSelector(
    ({ giftSearch }) => giftSearch
  )

  const savedSearches = useSelector(selectSavedGiftSearches)

  const { results: pipelineStages } = useSelector(selectPipelineStages)

  const updateFilterStack = () => {
    const lastInStack = filterStack[filterStack.length - 1]
    if (
      (filterStack.length === 0 && !_.isEmpty(filters)) ||
      (filterStack.length > 0 && !_.isEqual(lastInStack, filters))
    ) {
      setFilterStack([...filterStack, filters])
    }
  }

  const reset = async () => {
    dispatch(resetGiftSearch)
    await trackGiftSearchButtonClick({ button: 'action.reset' })
  }

  const handleRemoveFilter = filterKey => {
    dispatch(setGiftSearchFilterByKey({ [filterKey]: null }))
  }

  const undo = async () => {
    const stack = [...filterStack]
    if (stack.length > 0) {
      stack.pop()
      setFilterStack(stack)
      dispatch(setGiftSearchFilters(stack[stack.length - 1] || {}))
    }
    await trackGiftSearchButtonClick({ button: 'action.undo' })
  }

  const locale = `${i18n.language}-CA`

  const totalSum = new Intl.NumberFormat(locale).format(results.count)
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(stats.giftAmountTotal || 0)

  const graphicYearRangeLabel =
    stats.yearRange &&
    !_.isEmpty(stats.yearRange) &&
    t.explorer.graphicYearRange
      .replace('{yearMin}', stats.yearRange.gte)
      .replace('{yearMax}', stats.yearRange.lte)

  useEffect(() => {
    updateFilterStack()
  }, [filters])

  useEffect(() => {
    if (!urlSynced) {
      dispatch(syncGiftSearchURL(location.search))
    }
    dispatch(debouncedSearchGifts)
  }, [pagination, sorting, filters])

  useEffect(() => {
    dispatch(debouncedGetGiftStats)
    dispatch(updateGiftSearchNameCache)
  }, [filters])

  useEffect(() => {
    dispatch(getSavedSearches({ type: 'gift' }))
    dispatch(getPipelines())
  }, [])

  useEffect(() => {
    if (!pipelineStages) {
      dispatch(getPipelineStages())
    }
  }, [pipelineStages])

  return (
    <div className="Explorer">
      <ReportProfileError />
      <Grid fluid className="grid tw-clear-both">
        <Row>
          <Col xs={12}>
            <h1 className={'ge-title tw-text-2xl'}>{t.explorer.title}</h1>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <h2 className={'tw-text-lg tw-font-semibold tw-mt-0'}>{t.explorer.keywordSearch}</h2>
              <SearchBar />
              <div className="">
                <SavedSearchesDropdown savedSearches={savedSearches} />
              </div>
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <div className="sm:tw-flex tw-justify-between tw-mb-1">
                <h2 className={'tw-text-lg tw-font-semibold tw-mb-1 tw-mt-0'}>
                  {t.explorer.results}
                </h2>
                <div className="tw-flex tw-gap-1 tw-flex-wrap tw-mb-2">
                  <SaveSearch savedSearches={savedSearches} />
                  <ButtonWithIcon
                    onClick={undo}
                    disabled={filterStack.length === 0}
                    color="grey"
                    label={t.explorer.undo}
                    Icon={Undo}
                  />
                  <ButtonWithIcon
                    onClick={reset}
                    color="dark-grey"
                    label={t.explorer.reset}
                    Icon={Close}
                  />
                </div>
              </div>
              <ResultsSummary filters={filters} handleRemoveFilter={handleRemoveFilter} />
              <div>
                <DataTable records={results.records} count={results.count} />
                <div className="tw-mt-4 tw-flex tw-gap-4">
                  <div className="tw-text-center">
                    <p className="tw-my-1">{t.explorer.totalGiftCount}</p>
                    <p className="tw-my-0 tw-font-semibold tw-text-lg">{totalSum}</p>
                  </div>
                  <div className="tw-text-center">
                    <p className="tw-my-1">{t.explorer.totalGiftAmount}</p>
                    <p className="tw-my-0 tw-font-semibold tw-text-lg">{formattedAmount}</p>
                  </div>
                </div>
              </div>
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <h2 className={'tw-text-lg tw-font-semibold tw-mt-0'}>
                {t.explorer.graphicTitleFunder}
              </h2>
              <p className="tw-mb-3">
                <small>{graphicYearRangeLabel}</small>
              </p>
              <BarChart />
            </Paper>
          </Col>
          <Col xs={12} md={6}>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <h2 className={'tw-text-lg tw-font-semibold tw-mt-0'}>
                {t.explorer.graphicTitleRecipient}
              </h2>
              <p className="tw-mb-3">
                <small>{graphicYearRangeLabel}</small>
              </p>
              <PieChart />
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="tw-my-6">{t.explorer.disclaimer}</p>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default Explorer
