import { Close } from '@mui/icons-material'
import { Button, Dialog } from '@mui/material'

import { useState } from 'react'

import { trackGiftSearchButtonClick } from 'utils/mixpanel'

import { highlightText, truncateHighlightedText } from '../helpers'

const FullTextModal = ({ title, fullText }) => {
  const [open, setOpen] = useState(false)

  const highlightedText = highlightText(fullText)
  const truncatedText = truncateHighlightedText(highlightedText, 45)

  const handleClose = () => setOpen(false)
  const handleOpen = async () => {
    setOpen(true)
    await trackGiftSearchButtonClick({ button: 'table_popup.details' })
  }

  return (
    <>
      <div className="full-text-modal">
        <span className="tw-mr-1">{truncatedText}</span>
        <Button
          onClick={handleOpen}
          className="show-modal-button"
          style={{ minWidth: '14px', height: '14px', lineHeight: '14px', padding: '0' }}
        >
          ...
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose} className="Explorer">
        <div className="ge-dialog-close">
          <Close onClick={handleClose} />
        </div>
        <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
          {title}
        </p>
        <p>{highlightedText}</p>
      </Dialog>
    </>
  )
}

export default FullTextModal
