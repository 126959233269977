import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import TextField from '@mui/material/TextField'

import { useRef } from 'react'

const SimpleNumberField = ({
  id,
  className,
  label,
  maxLength,
  placeholder,
  errorMessage,
  min,
  max,
  step = 1,
  ...props
}) => {
  const ref = useRef(null)

  const setValue = value => {
    if (Number.isNaN(value)) {
      ref.current.value = 0
    } else if (min !== undefined && value < min) {
      ref.current.value = min
    } else if (max !== undefined && value > max) {
      ref.current.value = max
    } else {
      ref.current.value = value
    }
  }

  const increment = e => {
    e.stopPropagation()
    setValue(+ref.current?.value + step)
  }

  const decrement = e => {
    e.stopPropagation()
    setValue(+ref.current?.value - step)
  }

  return (
    <div className="tw-flex tw-flex-col tw-mb-2">
      {label && (
        <label htmlFor={id} className="tw-mb-1">
          {label}
        </label>
      )}
      <div className="tw-flex tw-flex-row">
        <TextField
          type="number"
          id={id}
          name={id}
          className="ge-simple-number-field"
          placeholder={placeholder}
          error={!!errorMessage}
          helperText={errorMessage}
          inputProps={{ maxLength, pattern: '[0-9]*', ref }}
          {...props}
        />
        <div className="ge-number-controls">
          <div onClick={increment}>
            <ArrowDropUp />
          </div>
          <div onClick={decrement}>
            <ArrowDropDown />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleNumberField
