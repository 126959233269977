import {
  SET_PIPELINE,
  SET_PIPELINE_LOADING,
  ADD_OR_UPDATE_PIPELINE_ITEM
} from 'store/actions/pipeline'

const initialState = {
  isLoading: true,
  results: []
}

const pipelineReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_PIPELINE_LOADING:
      const { isLoading } = payload
      return { ...state, isLoading }
    case SET_PIPELINE:
      const { data } = payload
      return { ...state, ...data }
    case ADD_OR_UPDATE_PIPELINE_ITEM:
      const { item } = payload
      const results = [...state.results]
      const index = results.findIndex(opportunity => opportunity.id === item.id)
      if (index >= 0) {
        results[index] = item
      } else {
        results.push(item)
      }
      return { ...state, results: [...results] }
    default:
      return state
  }
}

export default pipelineReducer
